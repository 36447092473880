.App {
  font-family: PingFangSC-Medium, sans-serif;
  text-align: center;
}

.clock-time {
  position: relative;
  width: 220px;
  height: 192px;
  border-radius: 8%;
}
/* .clock-time::after {
  position: absolute;
  display: inline-block;
  content: ' ';
  width: 100%;
  height: 6px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #000000;
} */
